import React, { useEffect, useState, useRef } from "react";
import { getEntities } from "../../api/api";
import { Card, ThemeProvider, ListItemText, IconButton, Accordion, AccordionSummary, AccordionDetails, Tooltip, Grid, } from "@mui/material";
import { theme } from "../../wizard/utils";
import Icon from "@mdi/react";
import { mdiOfficeBuilding, mdiLockReset, mdiLockAlert, mdiAccount, mdiChevronDown, mdiLockOpen, } from "@mdi/js";
import Stack from "@mui/material/Stack";
import moment from "moment";
import "./UserEdit.css";
import ModalTemplate from "./UserModals";
import UserAvatar from "../UserAvatar";
import SnackbarComponent from "../../hooks/utils/Snackbar";
const EditUser = ({ $state, AdministrationService }) => {
    const [user, setUser] = useState({
        name: "",
        email: "",
        id: "",
        groups: [],
        createdAt: moment(),
        lastLogin: moment(),
        imap: false,
        blocked: false,
        emailVerified: false,
        entityIds: [],
    });
    const [entities, setEntities] = useState([]); // todas as empresas
    const [modalEntities, setModalEntities] = useState([]); // empresas associadas ao user
    const [modalOpen, setModalOpen] = useState(false);
    const [modalNumber, setModalNumber] = useState(Number);
    const [block, setBlock] = useState(user.blocked);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackSeverity, setSnackSeverity] = useState("");
    const dataFetched = useRef(false); //reference a value that’s not needed for rendering.
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const resource = await AdministrationService.getUser($state.params.id);
                setUser(resource);
                setModalEntities((oldArray) => [...oldArray, ...resource.entities]);
                const entitiesData = await getEntities();
                setEntities(entitiesData);
            }
            catch (error) {
                console.error("Erro ao carregar utilizadores:", error.message);
            }
            finally {
                dataFetched.current = true;
            }
        };
        if (!dataFetched.current) {
            fetchUsers();
        }
    }, [$state.params.id, user.blocked]); // Only re-run the effect if user/useiId changes
    const convertToDate = (date) => {
        const dateObject = new Date(date);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
        return `${year}-${month}-${day}`;
    };
    const convertToDateTime = (date) => {
        const dateObject = new Date(date);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
        const hour = dateObject.getHours();
        const minute = dateObject.getMinutes();
        return `${year}-${month}-${day} ${hour}:${minute}`;
    };
    const handleModal = (modal) => {
        setModalOpen(true);
        setModalNumber(modal);
        if (modal == 2) {
            setModalEntities(entities);
            modalEntities.map((ent) => { });
        }
        else {
            setModalEntities([]);
        }
    };
    const handleCloseModal = (message, severity) => {
        setModalOpen(false);
        setModalNumber(null);
        if (typeof message === "string" && typeof severity === "string") {
            setSnackMessage(message);
            setSnackSeverity(severity); // normal Snackbar || success, info, warning, error -> alert Snackbar
            setOpenSnackbar(true);
        }
    };
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    const handleBlock = async () => {
        try {
            user.blocked = !user.blocked;
            setBlock(user.blocked);
            await AdministrationService.saveUser(user);
            setSnackMessage(block ? "Utilizador desbloqueado" : "Utilizador bloqueado");
            setSnackSeverity("normal"); // normal Snackbar || success, info, warning, error -> alert Snackbar
            setOpenSnackbar(true);
        }
        catch (error) {
            console.error("Erro ao bloquear utilizador:", error.message);
        }
    };
    return (<ThemeProvider theme={theme}>
      <ModalTemplate user={user} setUser={setUser} modalType={modalNumber} open={modalOpen} onClose={handleCloseModal} entities={modalEntities} administrationService={AdministrationService}/>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Card sx={{ padding: "10px" }}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <UserAvatar name={user.name}/>
                <ListItemText primary={user.name} secondary={user.email}/>
              </Stack>

              <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
                <Tooltip title="Alterar password">
                  <IconButton edge="end" onClick={() => handleModal(1)}>
                    <Icon path={mdiLockReset} size={1.1}/>
                  </IconButton>
                </Tooltip>
                <Tooltip title={block ? "Desbloquear utilizador" : "Bloquear utilizador"}>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleBlock()}>
                    {block ? (<Icon path={mdiLockOpen} size={1.1}/>) : (<Icon path={mdiLockAlert} size={1.1}/>)}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Associar empresa">
                  <IconButton edge="end" aria-label="edit" onClick={() => handleModal(2)}>
                    <Icon path={mdiOfficeBuilding} size={1.1}/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Alterar dados">
                  <IconButton edge="end" aria-label="edit" onClick={() => handleModal(0)}>
                    <Icon path={mdiAccount} size={1.1}/>
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<Icon path={mdiChevronDown} size={1}/>} aria-controls="panel1-content" id="panel1">
              Informação da conta
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                <div className="col-sm-6">
                  <b>Data de criação da conta</b>
                </div>
                <div className="col-sm-6">
                  {convertToDate(user.createdAt.toString())}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <b>Último login</b>
                </div>
                <div className="col-sm-6">
                  {user.lastLogin == undefined
            ? ""
            : convertToDateTime(user.lastLogin.toString())}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <b>Verificação de e-mail</b>
                </div>
                <div className="col-sm-6">
                  {user.emailVerified ? "Conta verificada" : "Não verificada"}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <b>Estado</b>
                </div>
                <div className="col-sm-6">
                  {!user.blocked ? "Ativo" : "Bloqueado"}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <b>Autenticação IMAP</b>
                </div>
                <div className="col-sm-6">
                  {user.imap ? "Ativo" : "Não ativo"}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<Icon path={mdiChevronDown} size={1}/>} aria-controls="panel1-content" id="panel2">
              Associado a
            </AccordionSummary>
            <AccordionDetails>
              {user.entityIds.map((userEntity) => entities.map((entity) => {
            if (userEntity === entity.id) {
                return (<ul key={userEntity}>
                        <b>{entity.name}</b>
                      </ul>);
            }
        }))}
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<Icon path={mdiChevronDown} size={1}/>} aria-controls="panel1-content" id="panel3">
              Cargos
            </AccordionSummary>
            <AccordionDetails>
              <ListItemText primary="Membro de" secondary={user.groups.map((g, index) => index === user.groups.length - 1 ? g.name : g.name + ", ")}/>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <SnackbarComponent message={snackMessage} open={openSnackbar} severity={snackSeverity} onClose={closeSnackbar}/>
    </ThemeProvider>);
};
export default EditUser;
