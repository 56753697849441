import React, { useEffect, useRef, useState } from "react";
import { Box, Card, CardActions, Divider, Grid, IconButton, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, } from "@mui/material";
import Chart from "chart.js";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight, mdiCurrencyEur, mdiInformation, mdiLightningBolt, } from "@mdi/js";
import moment from "moment";
const PredictYearConsumption = ({ DashboardService }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [yearData, setYearData] = useState([]);
    const [monthData, setMonthData] = useState([]);
    const [goFetch, setGoFetch] = useState(true);
    const [yearInfo, setYearInfo] = useState({
        currentTotalConsumption: 0,
        currentTotalConsumptionCost: 0,
        yearTotalConsumption: 0,
        yearTotalConsumptionCost: 0,
        previousYearTotalConsumption: 0,
        previousYearTotalConsumptionCost: 0,
        differenceValueConsumption: 0,
        differenceValueCost: 0,
        differencePercentageConsumption: 0,
        differencePercentageCost: 0,
    });
    const [currentMonth, setCurrentMonth] = useState(0);
    const [dateLabel, setDateLabel] = useState("");
    const [dataType, setDataType] = useState("kWh"); /// Select -> kWh - euro
    const [dateChart, setDateChart] = useState("mes"); /// Select -> meses - dias
    useEffect(() => {
        if (goFetch) {
            fetchData();
            setCurrentMonth(new Date().getMonth());
        }
        else {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy(); // Destroy the existing chart instance
            }
            if (dateChart === "mes") {
                setDateLabel(monthData[currentMonth].month);
                createChart(monthData[currentMonth].resources);
            }
            else {
                setDateLabel(yearData[0][0].month.slice(0, 4));
                createChart(yearData);
            }
        }
    }, [currentMonth, monthData, dateChart, dataType]);
    const fetchData = async () => {
        try {
            const year = await DashboardService.getPredictYear();
            const temp = year[year.length - 1];
            const fullYearData = {
                currentTotalConsumption: Math.round(temp.current_year_total_consumption),
                currentTotalConsumptionCost: Math.round(temp.current_year_total_cost),
                yearTotalConsumption: Math.round(temp.predicted_year_total_consumption),
                yearTotalConsumptionCost: Math.round(temp.predicted_year_total_cost),
                previousYearTotalConsumption: Math.round(temp.previous_year_total_consumption),
                previousYearTotalConsumptionCost: Math.round(temp.previous_year_total_cost),
                differenceValueConsumption: Math.round(temp.difference_value_consumption),
                differenceValueCost: Math.round(temp.difference_value_cost),
                differencePercentageConsumption: Math.round(temp.difference_percentage_consumption),
                differencePercentageCost: Math.round(temp.difference_percentage_cost),
            };
            setYearInfo(fullYearData);
            setGoFetch(false);
            const monthData = {};
            const today = moment().format("YYYY-MM-DD"); // Obtém a data de hoje no formato YYYY-MM-DD
            const todayData = {
                date: "",
                consumption: 0,
                cost: 0,
                predict: 0,
                predictCost: 0,
            };
            year.forEach((day) => {
                const yearMonth = day.date.substring(0, 7); // Extracts only the year and month (YYYY-MM)
                if (!monthData[yearMonth]) {
                    monthData[yearMonth] = {
                        month: yearMonth,
                        resources: [],
                    };
                }
                const resourceEntry = {
                    date: day.date,
                };
                if (day.date.slice(0, 10) === today) {
                    if (day.consumption === undefined) {
                        todayData.predict = Math.round(day.prediction_consumo / 1000);
                        todayData.predictCost = Math.round(day.prediction_custo);
                        todayData.date = day.date;
                        monthData[yearMonth].resources.push(todayData);
                    }
                }
                if (day.date.slice(0, 10) < today) {
                    resourceEntry.consumption = Math.round(day.consumption / 1000);
                    resourceEntry.cost = Math.round(day.cost);
                    resourceEntry.predict = 0;
                    resourceEntry.predictCost = 0;
                }
                else if (day.date.slice(0, 10) > today) {
                    resourceEntry.predict = Math.round(day.prediction_consumo / 1000);
                    resourceEntry.predictCost = Math.round(day.prediction_custo);
                    resourceEntry.consumption = 0;
                    resourceEntry.cost = 0;
                }
                if (day.date.slice(0, 10) !== today) {
                    monthData[yearMonth].resources.push(resourceEntry);
                }
            });
            const data = Object.values(monthData);
            calculateYearData(year);
            setMonthData(data);
        }
        catch (error) {
            console.error("Erro ao carregar consumos:", error.message);
        }
    };
    const handleMonth = (action) => {
        action === "next"
            ? setCurrentMonth((prevIndex) => prevIndex + 1)
            : setCurrentMonth((prevIndex) => prevIndex - 1);
    };
    const handleSelectType = (event) => {
        setDataType(event.target.value);
    };
    const handleSelectDate = (event) => {
        setDateChart(event.target.value);
    };
    const formatNumber = (number) => {
        return new Intl.NumberFormat("de-DE").format(number); // Using 'de-DE' locale for spaces
    };
    const calculateYearData = (consumptions) => {
        const monthData = {};
        consumptions.forEach((day) => {
            const yearMonth = day.date.substring(0, 7); // Extrai apenas o ano e o mês (YYYY-MM)
            const year = yearMonth.substring(0, 4);
            if (!monthData[year]) {
                monthData[year] = [];
            }
            const existingMonthEntry = monthData[year].find((entry) => entry.month === yearMonth);
            if (existingMonthEntry) {
                existingMonthEntry.consumption += day.consumption || 0;
                existingMonthEntry.cost += day.cost || 0;
                existingMonthEntry.predictConsumption += day.prediction_consumo || 0;
                existingMonthEntry.predictCost += day.prediction_custo || 0;
            }
            else {
                // predi
                monthData[year].push({
                    month: yearMonth,
                    consumption: day.consumption || 0,
                    cost: day.cost || 0,
                    predictConsumption: day.prediction_consumo || 0,
                    predictCost: day.prediction_custo || 0,
                });
            }
        });
        const data = Object.values(monthData);
        setYearData(data);
    };
    const createChart = (chartData) => {
        if (chartRef.current) {
            const ctx = chartRef.current.getContext("2d");
            if (ctx) {
                let labels, values, backgroundColors;
                if (dateChart === "mes") {
                    labels = chartData.map((entry) => entry.date.slice(0, 10));
                    values = chartData.map((entry) => Math.round(dataType === "kWh"
                        ? entry.consumption || entry.predict
                        : entry.cost || entry.predictCost));
                    backgroundColors = chartData.map((entry) => entry.predict || entry.predictCost
                        ? "rgba(255, 159, 64, 0.2)"
                        : "rgba(75, 192, 192, 0.2)");
                }
                else {
                    labels = chartData[0].map((entry) => entry.month.slice(0, 7));
                    let month = moment().month() + 1;
                    values = chartData[0].map((entry) => {
                        if (dataType === "kWh") {
                            if (entry.month.slice(5, 7) == month) {
                                return Math.round((entry.consumption + entry.predictConsumption) / 1000);
                            }
                            else {
                                return Math.round(entry.consumption / 1000 || entry.predictConsumption / 1000);
                            }
                        }
                        else {
                            if (entry.month.slice(5, 7) == month) {
                                return Math.round(entry.cost + entry.predictCost);
                            }
                            else {
                                return Math.round(entry.cost || entry.predictCost);
                            }
                        }
                    });
                    backgroundColors = chartData[0].map((entry) => entry.predictConsumption || entry.predictCost
                        ? "rgba(255, 159, 64, 0.2)"
                        : "rgba(75, 192, 192, 0.2)");
                }
                const data = {
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            borderColor: backgroundColors.map((color) => color.replace("0.2", "1")),
                            backgroundColor: backgroundColors,
                            fill: false,
                            lineTension: 0.1,
                            borderDash: [],
                        },
                    ],
                };
                chartInstanceRef.current = new Chart(ctx, {
                    type: "bar",
                    data: data,
                    options: {
                        responsive: true,
                        scales: {
                            xAxes: [
                                {
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: dateChart === "mes" ? "Dia do Mês" : "Mês",
                                    },
                                    ticks: {
                                        autoSkip: false,
                                        maxTicksLimit: dateChart === "mes" ? 31 : 12,
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: dataType === "kWh"
                                            ? "Consumo / Previsão (kWh)"
                                            : "Consumo / Previsão (€)",
                                    },
                                    ticks: {
                                        callback: function (value) {
                                            return value
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                        },
                                    },
                                },
                            ],
                        },
                        tooltips: {
                            mode: "index",
                            intersect: false,
                        },
                    },
                });
            }
        }
    };
    const iconButtons = () => {
        return (<Box>
        {currentMonth === 0 ? (<IconButton aria-label="previous" onClick={() => handleMonth("previous")} disabled={true}>
            <Icon path={mdiChevronLeft} size={1}/>
          </IconButton>) : (<Tooltip title="Mês anterior">
            <IconButton aria-label="previous" onClick={() => handleMonth("previous")}>
              <Icon path={mdiChevronLeft} size={1}/>
            </IconButton>
          </Tooltip>)}

        {currentMonth === 11 ? (<IconButton aria-label="next" onClick={() => handleMonth("next")} disabled={true}>
            <Icon path={mdiChevronRight} size={1}/>
          </IconButton>) : (<Tooltip title="Próximo mês">
            <IconButton aria-label="next" onClick={() => handleMonth("next")}>
              <Icon path={mdiChevronRight} size={1}/>
            </IconButton>
          </Tooltip>)}
      </Box>);
    };
    return (<Box sx={{ mb: 2 }}>
      {yearData.length !== 0 ? (<Box>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Card sx={{ width: "100%", p: 1 }}>
                <Stack spacing={2} direction="column">
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Stack spacing={1} direction="row">
                      <Box sx={{ marginTop: "100px" }}>
                        <Typography variant="h6">
                          Previsão de consumos
                        </Typography>
                      </Box>
                      <Tooltip title="Este gráfico apresenta um estimativa da previsão dos próximos consumos com base nos consumos anteriores">
                        <Icon path={mdiInformation} size={1}/>
                      </Tooltip>
                    </Stack>

                    <Select id="chartData" value={dataType} onChange={handleSelectType}>
                      <MenuItem value="kWh">kWh</MenuItem>
                      <MenuItem value="euro">euro €</MenuItem>
                    </Select>

                    <Select id="chartDataType" value={dateChart} onChange={handleSelectDate}>
                      <MenuItem value="ano">Ano</MenuItem>
                      <MenuItem value="mes">Mês</MenuItem>
                    </Select>
                    <Typography>{dateLabel}</Typography>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                      {dateChart === "mes" ? iconButtons() : <Box></Box>}
                    </Stack>
                  </Stack>
                  <canvas ref={chartRef}></canvas>
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                    <Box sx={{
                backgroundColor: "#dbf2f2",
                width: "22px",
                height: "14px",
                border: "2px solid #4bc0c0",
                margin: "5px",
            }}></Box>
                    <Typography variant="body2" style={{ color: "#666666", cursor: "default" }}>
                      Consumo real {dataType === "kWh" ? "(kWh)" : "(€)"}
                    </Typography>
                    <Box sx={{
                backgroundColor: "#ffecd9",
                width: "22px",
                height: "14px",
                border: "2px solid #ff9f40",
                margin: "5px",
            }}></Box>
                    <Typography variant="body2" style={{ color: "#666666", cursor: "default" }}>
                      Previsão de consumo {dataType === "kWh" ? "(kWh)" : "(€)"}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            </Grid>
            <Grid item md={4} xs={12}>
              <Card sx={{ width: "100%", height: "100%" }}>
                <Divider />
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6" gutterBottom>
                            Resumo de Consumos
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Icon path={mdiLightningBolt} size={1}/>
                        </TableCell>
                        <TableCell align="center">
                          <Icon path={mdiCurrencyEur} size={1}/>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Total Consumido</TableCell>
                        <TableCell align="center">
                          {formatNumber(yearInfo.currentTotalConsumption / 1000)}{" "}
                          kWh
                        </TableCell>
                        <TableCell align="center">
                          {formatNumber(yearInfo.currentTotalConsumptionCost)} €
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Previsão Consumo</TableCell>
                        <TableCell align="center">
                          {formatNumber(Math.round(yearInfo.yearTotalConsumption / 1000))}{" "}
                          kWh
                        </TableCell>
                        <TableCell align="center">
                          {formatNumber(yearInfo.yearTotalConsumptionCost)} €
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Consumo Ano Anterior</TableCell>
                        <TableCell align="center">
                          {formatNumber(Math.round(yearInfo.previousYearTotalConsumption / 1000))}{" "}
                          kWh
                        </TableCell>
                        <TableCell align="center">
                          {formatNumber(yearInfo.previousYearTotalConsumptionCost)}{" "}
                          €
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Diferença de consumo</TableCell>
                        <TableCell align="center" sx={{
                backgroundColor: yearInfo.differenceValueCost < 0
                    ? "#4bc04b33"
                    : "#ff638433",
            }}>
                          {formatNumber(Math.round(yearInfo.differenceValueConsumption / 1000))}{" "}
                          kWh
                        </TableCell>
                        <TableCell align="center" sx={{
                backgroundColor: yearInfo.differenceValueCost < 0
                    ? "#4bc04b33"
                    : "#ff638433",
            }}>
                          {formatNumber(yearInfo.differenceValueCost)} €
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Diferença percentual </TableCell>
                        <TableCell align="center" sx={{
                backgroundColor: yearInfo.differencePercentageConsumption < 0
                    ? "#4bc04b33"
                    : "#ff638433",
            }}>
                          {yearInfo.differencePercentageConsumption}{" "}
                          <Box component="span" fontWeight="bold">
                            %
                          </Box>
                        </TableCell>
                        <TableCell align="center" sx={{
                backgroundColor: yearInfo.differencePercentageCost < 0
                    ? "#4bc04b33"
                    : "#ff638433",
            }}>
                          {yearInfo.differencePercentageCost}{" "}
                          <Box component="span" fontWeight="bold">
                            %
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box mt={2}>
                  <Stack spacing={2}>
                    <Box>
                      <Stack direction={"row"}>
                        <Box sx={{
                backgroundColor: "#4bc04b33",
                width: "22px",
                height: "14px",
                margin: "5px",
            }}></Box>
                        <Typography>- Poupança</Typography>
                      </Stack>
                      <Stack direction={"row"}>
                        <Box sx={{
                backgroundColor: "#ff638433",
                width: "22px",
                height: "14px",
                margin: "5px",
            }}></Box>
                        <Typography>- Gasto extra</Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
                <CardActions>
                  <Box>
                    <Typography>
                      <Box component="span" fontWeight="bold">
                        Total Consumido{" "}
                      </Box>{" "}
                      - Energia consumida desde o início do ano.
                    </Typography>
                    <Typography>
                      <Box component="span" fontWeight="bold">
                        Previsão de Consumo{" "}
                      </Box>{" "}
                      - Previsão da energia a consumir até ao final do ano.
                    </Typography>
                    <Typography>
                      <Box component="span" fontWeight="bold">
                        Consumo Ano anterior{" "}
                      </Box>{" "}
                      - Total de energia consumida do ano anterior.
                    </Typography>
                    <Typography>
                      <Box component="span" fontWeight="bold">
                        Diferença de consumo{" "}
                      </Box>{" "}
                      - Diferença da energia consumida entre a previsão de
                      consumo e consumo do ano anterior.
                    </Typography>
                    <Typography>
                      <Box component="span" fontWeight="bold">
                        Diferença percentual{" "}
                      </Box>{" "}
                      - Diferença da energia consumida entre a previsão de
                      consumo e consumo do ano anterior em percentagem.
                    </Typography>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>) : (<Box></Box>)}
    </Box>);
};
export default PredictYearConsumption;
